<template>
  <v-menu
    v-model="menu_date"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        label="Fecha de nacimiento"
        append-icon="mdi-calendar"
        :rules="[(v) => !!v || 'El campo es requerido']"
        filled
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="selected_date"
      @input="menu_date = false"
      @change="$emit('sendDate', selected_date)"
      locale="es"
    ></v-date-picker>
  </v-menu>
</template>
<script>
export default {
  data: () => ({
    menu_date: false,
    selected_date: "",
  }),
  computed: {
    dateFormatted() {
      if (!this.selected_date) return null;

      const [year, month, day] = this.selected_date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>
