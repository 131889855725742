<template>
  <v-form ref="formp" @submit.prevent="sendData">
    <v-row class="mt-1">
      <v-col cols="12" sm="3">
        <v-text-field
          v-model="postulante.cedula_dni"
          label="Cédula de identidad o DNI"
          :rules="[(v) => !!v || 'El campo es requerido']"
          hint="1234567 o 1234567-1F"
          clearable
          filled
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="3">
        <custom-datepicker @sendDate="receiveDate"></custom-datepicker>
      </v-col>
      <v-col cols="12" sm="6">
        <v-btn type="submit" large color="primary" :loading="bloading">
          <v-icon>mdi-magnify</v-icon>
          Buscar
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import CustomDatepicker from "./CustomDatepicker.vue";
import { mapActions } from "vuex";

export default {
  name: "form-search",
  props: ["bloading"],
  components: {
    CustomDatepicker,
  },
  data: () => ({
    postulante: {
      cedula_dni: "",
      fecha_nacimiento: "",
    },
  }),
  methods: {
    ...mapActions(["toast"]),
    receiveDate(selected_date) {
      this.postulante.fecha_nacimiento = selected_date;
    },
    sendData() {
      if (this.$refs.formp.validate()) {
        this.$emit("sendApplicant", this.postulante);
      } else {
        this.toast({
          color: "info",
          text: "Ingrese Cédula de identidad y Fecha de nacimiento",
        });
      }
    },
  },
};
</script>
