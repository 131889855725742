import Url from "../utils/environment";
import axios from "axios";
//console.log(this.$axios, axios);
axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("access_token");
    if (token) {
      config.headers.common["Authorization"] = token;
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  (error) => {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          //do something
          break;

        case 401:
          alert("session expired");
          break;
        case 403:
          // router.replace({
          //   path: "/login",
          //   query: { redirect: router.currentRoute.fullPath },
          // });
          break;
        case 404:
          alert("page not exist");
          break;
        case 502:
          // setTimeout(() => {
          //     router.replace({
          //       path: "/login",
          //       query: {
          //         redirect: router.currentRoute.fullPath,
          //       },
          //     });
          //   }, 1000);
          break;
      }
      return Promise.reject(error.response);
    }
  }
);

export default {
  // ADMISION
  svcAdmissionAnnouncement() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          Url.getAPI() + "admision/verificar/convocatoria",
          Url.getHeaderExt()
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  svcInsitutionParam() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          Url.getAPI() + "admision/convocatoria/institucion",
          Url.getHeader()
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  svcSpecialties(institucion_id, auth) {
    return new Promise((resolve, reject) => {
      const header = auth ? Url.getHeader() : Url.getHeaderExt();
      axios
        .post(
          Url.getAPI() + "institucion/especialidades",
          { institucion_id: institucion_id },
          header
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  svcModality() {
    //NO SE USA
    return new Promise((resolve, reject) => {
      axios
        .get(Url.getAPI() + "admision/modalidad", Url.getHeader())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  svcMigrateWrite(convocatoria_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Url.getAPI() + "admision/migrar/escrita",
          { convocatoria_id: convocatoria_id },
          Url.getHeader()
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  svcMigrateAptitude(convocatoria_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Url.getAPI() + "admision/migrar/aptitud",
          { convocatoria_id: convocatoria_id },
          Url.getHeader()
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  svcCalculateResult(convocatoria_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Url.getAPI() + "admision/calcular/resultado",
          { convocatoria_id: convocatoria_id },
          Url.getHeader()
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  svcProcessAdmited(convocatoria_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Url.getAPI() + "admision/procesar/admitido",
          { convocatoria_id: convocatoria_id },
          Url.getHeader()
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  svcSwitchAdmited(postulante) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Url.getAPI() + "admision/postulante/admitir",
          postulante,
          Url.getHeader()
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  svcApplicants(route, options) {
    return new Promise((resolve, reject) => {
      axios
        .post(Url.getAPI() + route, options, Url.getHeader())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  svcCheckResult(postulante) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Url.getAPI() + "admision/consultar/resultado",
          postulante,
          Url.getHeaderExt()
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  svcCheckResultB(postulante) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Url.getAPI() + "admision/consultar/resultadoB",
          postulante,
          Url.getHeaderExt()
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  svcListAdmitidos(postulante) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Url.getAPI() + "admision/consultar/resultadoAdmitidos",
          postulante,
          Url.getHeaderExt()
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  svcReportAdmittedModA(convocatoria_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Url.getAPI() + "admision/reporte/admitido/ma",
          { convocatoria_id: convocatoria_id },
          Url.getHeader()
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  svcReportAdmittedModB(convocatoria_id) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Url.getAPI() + "admision/reporte/admitido/mb",
          { convocatoria_id: convocatoria_id },
          Url.getHeader()
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // COMPULSA
  svcCertifiesAnnouncement() {
    return new Promise((resolve, reject) => {
      axios
        .get(Url.getAPI() + "compulsa/convocatoria", Url.getHeaderExt())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  svcCertifiesAnnouncementUP() {
    return new Promise((resolve, reject) => {
      axios
        .get(Url.getAPI() + "compulsa/convocatoriaUP", Url.getHeaderExt())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  svcCertifiesAnnouncementBanco() {
    return new Promise((resolve, reject) => {
      axios
        .get(Url.getAPI() + "compulsa/convocatoriaBanco", Url.getHeaderExt())
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  //falta funciones
  svcCertifiesInscription(payload) {
    //SIN USAR
    return new Promise((resolve, reject) => {
      axios
        .post(
          Url.getAPI() + "compulsa/postulante/impresion",
          payload,
          Url.getHeaderExt()
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  svcCertifiesSearchApplicant(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Url.getAPI() + "compulsa/postulante/buscar",
          payload,
          Url.getHeaderExt()
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  svcProcessCertificate(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Url.getAPI() + "compulsa/personal/proceso",
          payload,
          Url.getHeaderExt()
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
