export default {
  getServe() {
    if (process.env.NODE_ENV === "production") {
      return "https://dgfmapi.minedu.gob.bo/";
    } else {
      return "http://172.20.16.251:4000/";
    }
  },
  getAPI() {
    return this.getServe() + "api/";
  },
  getLogin() {
    return this.getServe() + "usuario/auth";
  },
  setToken(token) {
    return sessionStorage.setItem("token", token);
  },
  getToken() {
    return sessionStorage.getItem("token") || "null";
  },
  removeToken() {
    return sessionStorage.removeItem("token");
  },
  getTokenExt() {
    return "oyJhbGciOiJIUzUxMiIsInR5cCI6Ik9URUMifQ.eyJzdWIiOiIxNjIzNDA2NDc5NjIyMCIsIm5hbWUiOiJZeHVKKnpHcTBVbVV1YyNxV2FzcSIsImlhdCI6OTg5MjM1MTYyMzkwMjJ9.w4IyJ1e_evWB8p3IumcJKHTrjAI5bmGwDGo7_VJtb0lferWyF9bRW7V2cRP8UVtP0GJoWivD1_ZWMs4wu4jzxQ";
  },
  getHeaderExt() {
    return {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.getTokenExt(),
      },
    };
  },
  getHeader() {
    return {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + this.getToken(),
      },
    };
  },
};
